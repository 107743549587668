import { AuthorizationConfig } from '../types';
import logger from './logger';
import { authorize } from '../services';

const authorization: AuthorizationConfig = {
  cacheKey: `@${process.env.REACT_APP_NAME}/token`,
  onAuthorize(token: string) {
    authorize(token);
    logger.debug('authorize with token: "%s"', token);
  },
  onUnauthorize(code: string, reason: string) {
    logger.warn('unauthorize (%s): "%s"', code, reason);
  },
  // async getInitialState() {
  //   const productInfo = await getProductInfo();
  //   return { productInfo };
  // },
};

export default authorization;
