import Cache from '../utils/Cache';

const PREFIX = process.env.REACT_APP_NAME || '';
const cache = new Cache(localStorage);

export const tokenCache = cache.cursor(`${PREFIX}/token`);
export const neuronTokenCache = cache.cursor('AUTH_TOKEN');
export const guidesCache = cache.cursor(`${PREFIX}/guides`);

export const superBookTokenCache = cache.cursor('SUPER_BOOK_TOKEN');
export const storeIdCache = cache.cursor('storeId');

export default cache;
