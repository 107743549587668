import { useEffect, useState } from 'react';
import { Excuter, Flag } from '@leyan/flag-runner';
import { FlagProps } from '@leyan/flag-runner/dist/Flag';
import { get } from 'lodash-es';

const FLAGR_ENDPOINT = process.env.REACT_APP_FLAGR_ENDPOINT as string;
const excuter = new Excuter({
  endpoint: FLAGR_ENDPOINT,
});

interface FeatureProps extends FlagProps {
  name: string;
  parse?: (flagResult: any) => any;
}
const PALTFORM = process.env.REACT_APP_PLATFORM;
const ENV = process.env.REACT_APP_ENV as string;
const createFeature = (
  featureName: string,
  flagKey: string,
  entityContext: { [x: string]: any },
  options?: Partial<Omit<FeatureProps, 'name' | 'key' | 'entityContext' | 'createContext'>>,
): FeatureProps => {
  const { schema = {}, ...otherOpts } = options || {};
  return {
    name: featureName,
    key: flagKey,
    schema,
    createContext: () => ({ entityContext }),
    ...otherOpts,
  };
};

function useFlagr(payload: any) {
  const [cartOrderReminderFlagr, setCartOrderReminderFlagr] = useState(false);  // 架构营销
  useEffect(() => {
    const getFlagr = async () => {
      if (payload?.store_id) {
        const flags: FeatureProps[] = [
          createFeature(
            'grayFeature',
            'ko98m2wynvf92pcz5' /** 搜索ecp-feature-gray */,
            { sid: payload.store_id, env: ENV, platform: PALTFORM },
            { parse: (raw: Flag) => get(raw, 'attachment') || {} },
          ),
        ];
        const result = await excuter.excute(flags.map((f) => new Flag(f))).catch(() => {
          return [] as Flag[];
        });

        const features = result.reduce((map, item, idx) => {
          const flag = flags[idx];
          if (flag?.name) {
            // eslint-disable-next-line no-param-reassign
            map[flag.name] = flag?.parse ? flag!.parse!(item) : item;
          }
          return map;
        }, {} as { [x: string]: any });

        setCartOrderReminderFlagr(features.grayFeature.cartOrderReminder);
      }

    }

    getFlagr();
  }, [payload]);


  return {
    cartOrderReminderFlagr
  };
};

export default useFlagr;
