import { AxiosResponse } from 'axios';
import AxiosClient, { headerAuthorizeAxiosClient } from '../utils/AxiosClient';

export interface Response<T> {
  data: T;
  msg: string;
  // eslint-disable-next-line camelcase
  sub_code: number;
}

export function resolve<T>(promise: Promise<AxiosResponse<Response<T>>>) {
  return promise.then((response) => {
    return response.data.data;
  });
}

export const client = new AxiosClient({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});
export const dataBoardClient = new AxiosClient({
  baseURL: process.env.REACT_APP_DATA_BOARD_API_ENDPOINT,
});

export const storehomeClient = new AxiosClient({
  baseURL: process.env.REACT_APP_STOREHOME_API_ENDPOINT,
});

export const itemClient = new AxiosClient({
  baseURL: process.env.REACT_APP_JD_ACCOUNT_API_ENDPOINT,
});

export const forbiddenWordClient = new AxiosClient({
  baseURL: process.env.REACT_APP_FORBIDDEN_WORD_API_ENDPOINT,
});

export const jdUploadClient = new AxiosClient({
  baseURL: process.env.REACT_APP_UPLOAD_IMAGE_ENDPOINT,
});

export const marketingHomeClient = new AxiosClient({
  baseURL: process.env.REACT_APP_MARKETING_HOME_ENDPOINT,
});

export const ecpItemClient = new AxiosClient({
  baseURL: process.env.REACT_APP_ECP_ITEM_API_ENDPOINT,
});

export function authorize(token: string) {
  headerAuthorizeAxiosClient(client, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });
  headerAuthorizeAxiosClient(dataBoardClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });
  headerAuthorizeAxiosClient(storehomeClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });

  headerAuthorizeAxiosClient(itemClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });

  headerAuthorizeAxiosClient(forbiddenWordClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });

  headerAuthorizeAxiosClient(jdUploadClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
  });

  headerAuthorizeAxiosClient(marketingHomeClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });

  headerAuthorizeAxiosClient(ecpItemClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });
}
