import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useRequest } from 'ahooks';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { decode } from 'jsonwebtoken';
import { PartialLocation } from 'history';
import { Emitter } from '@leyan/emitter';
import { useLocation } from 'react-router-dom';

import createNamedContext from '../../utils/createNamedContext';
import useTrackingRef from '../../hooks/useTrackingRef';
import useFlagr from '../../hooks/useFlagr';
import refine, { Refinable } from '../../utils/refine';
import AppLoading from '../AppLoading';
import useQuery, { useCreateSearch } from '../../hooks/useQuery';
import { fetchSubscribeInfo, SubscribeInfo } from './services';
import { growthBookFactory } from '../../services/growthbook';
import { storeIdCache, superBookTokenCache } from '../../services/cache';

/* eslint-disable camelcase */
type LeyanUser = {
  access_type: string;
  /** LDAP */
  nickname: string;
  /** uuid */
  username: string;
};

export type Token = {
  store_id: string;
  store_name: string;
  platform: string;
  apps: string[];
  nick: string;
  leyan_user: LeyanUser;
  iss: string;
  exp: number;
  iat: number;
  seller_id: string;
};
/* eslint-enable camelcase */

/**
 * 取消授权操作码
 */
export type UnauthorizeCode =
  | /* 授权码移除 */ 'TOKEN_REMOVE'
  | /* 授权码禁用 */ 'TOKEN_FORBIDDEN'
  | /* 授权码缺失 */ 'TOKEN_MISSING'
  | /* 授权码非法 */ 'TOKEN_INVALID'
  | /* 授权码过期 */ 'TOKEN_EXPIRED';

/**
 * 事件取消授权操作码
 */
export type EventsUnauthorizeCode =
  | /* 授权码移除 */ '@EVENT_TOKEN_REMOVE'
  | /* 授权码禁用 */ '@EVENT_TOKEN_FORBIDDEN';

/**
 * 授权事件
 */
export type AuthorizationEvents<T = unknown> = {
  /**
   * 授权事件
   * @param token 授权 token
   * @param payload 授权信息
   */
  authorize: (token: string, payload: T) => void;
  /**
   * 取消授权
   * @param code 操作码
   * @param reason 原因
   */
  unauthorize: (code: EventsUnauthorizeCode | UnauthorizeCode, reason: string) => void;
};

/**
 * 授权事件
 */
export const authorizationEvents = new Emitter<AuthorizationEvents>();

/**
 * 取消授权
 * @param code 操作码
 * @param reason 原因
 */
export function unauthorize(code: EventsUnauthorizeCode, reason: string) {
  authorizationEvents.emit('unauthorize', code, reason);
}

/**
 * 监听授权事件
 * @param handle 授权事件处理函数
 * @returns 取消监听事件
 */
export function onAuthorize<T = unknown>(handle: (token: string, payload: T) => void) {
  return authorizationEvents.on('authorize', (token, payload) => {
    handle(token, payload as T);
  });
}

/**
 * 监听取消授权事件
 * @param handle 取消授权事件处理函数
 * @returns 取消监听事件
 */
export function onUnauthorize(handle: (code: UnauthorizeCode, reason: string) => void) {
  return authorizationEvents.on('unauthorize', (code, reason) => {
    if (code !== '@EVENT_TOKEN_REMOVE' && code !== '@EVENT_TOKEN_FORBIDDEN') {
      handle(code, reason);
    }
  });
}

/**
 * 授权 hook 选项
 */
export interface AuthorizationHookOptions {
  /**
   * 存储对象
   */
  storage?: Storage;
  /**
   * 缓存键名
   */
  cacheKey?: string;
  /**
   * query 键名
   */
  queryKey?: string;
}

/**
 * 授权 hook aoi
 */
export interface AuthorizationHookApi<T = unknown> {
  /**
   * 授权中
   */
  loading: boolean;
  /**
   * 是否已经授权
   */
  authorized: boolean;
  /**
   * 授权 token
   */
  token: string | null;
  /**
   * 授权信息
   */
  payload: T | null;
  /**
   * 授权 token 来源
   */
  tokenSource: 'state' | 'query';
  /**
   * 授权
   * @param token 授权 token
   */
  authorize(token: string): void;
  /**
   * 取消授权
   * @param code 操作码
   * @param reason 原因
   */
  unauthorize(code: UnauthorizeCode, reason: string): void;
  /**
   * 创建恢复跳转位置
   * @param fallbackTo 默认路径
   */
  createRestoreToLocation(fallbackTo?: string): PartialLocation;
  /**
   * 创建重定向跳转位置
   * @param to 重定向路径
   */
  createRedirectToLocation(to?: string): PartialLocation;
  /**
   * 创建已授权跳转位置
   */
  createAuthorizedLocation(): PartialLocation;
  // flagr
  flagr: Record<string, any>;

  // 订阅信息
  subscribeInfo: SubscribeInfo | undefined;
}

/**
 * 授权 api hook
 * @param options 授权 api hook 选项
 */
export function useAuthorizationApi(options: AuthorizationHookOptions = {}) {
  const { storage = localStorage, cacheKey = '@authorization-token', queryKey = 'token' } = options;
  const location = useLocation();
  const query = useQuery();
  const createSearch = useCreateSearch();
  const [stateSuperBookToken, setStateSuperBookToken] = useState(() => {
    return superBookTokenCache.get();
  });
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [stateToken, setStateToken] = useState(() => {
    return storage.getItem(cacheKey);
  });

  const [payload, setPayload] = useState<Token | null>(null);

  const { [queryKey]: queryToken, access_token: accessToken } = query;
  const superBookToken = useMemo(() => {
    return accessToken || stateSuperBookToken;
  }, [accessToken, stateSuperBookToken]);
  const { token, tokenSource } = useMemo(() => {
    if (accessToken) {
      return typeof accessToken === 'string'
        ? {
            token: stateToken,
            tokenSource: 'state',
          }
        : {
            token: queryToken,
            tokenSource: 'query',
          };
    }
    return typeof queryToken === 'string'
      ? {
          token: queryToken,
          tokenSource: 'query',
        }
      : {
          token: stateToken,
          tokenSource: 'state',
        };
  }, [accessToken, queryToken, stateToken]);
  const internalApiRef = useTrackingRef({
    isTokenAuthorized(token: string) {
      return authorized && stateToken === token;
    },
    authorize(token: string, payload: Token) {
      storage.setItem(cacheKey, token);

      authorizationEvents.emit('authorize', token, payload);
    },
    unauthorize(code: UnauthorizeCode, reason: string) {
      storage.removeItem(cacheKey);

      authorizationEvents.emit('unauthorize', code, reason);
    },
  });

  const flagr = useFlagr(payload);

  const unauthorize = useCallback(
    (code: UnauthorizeCode, reason: string) => {
      internalApiRef.current.unauthorize(code, reason);

      batchedUpdates(() => {
        setStateToken(null);
        setLoading(false);
        setStateSuperBookToken(null);
        setAuthorized(false);
        setPayload(null);
      });
    },
    [internalApiRef],
  );
  const authorize = useCallback(
    (token: string | null) => {
      setLoading(true);
      if (token === null) {
        unauthorize('TOKEN_MISSING', 'token not provided');
        return;
      }
      if (internalApiRef.current.isTokenAuthorized(token)) {
        setLoading(false);
        return;
      }

      const payload = decode(token, { json: true }) as Token;

      if (payload === null) {
        unauthorize('TOKEN_INVALID', `token "${token}" invalid`);

        return;
      }

      if (typeof (payload as any).exp === 'number' && (payload as any).exp * 1000 < Date.now()) {
        unauthorize('TOKEN_EXPIRED', `token "${token}" expired`);

        return;
      }

      growthBookFactory.setAttributes({
        storeId: payload.store_id,
      });

      internalApiRef.current.authorize(token, payload);
      Promise.resolve().then(() => {
        batchedUpdates(() => {
          setStateToken(token);
          setStateSuperBookToken(superBookToken);
          storeIdCache.set(payload?.store_id as string);
          setLoading(false);
          setAuthorized(true);
          setPayload(payload);
        });
      });
    },
    [internalApiRef, superBookToken, unauthorize],
  );
  const createRestoreToLocation = useCallback(
    (fallbackTo) => {
      const { _from: to = fallbackTo, ...otherQuery } = query;

      return {
        pathname: to,
        search: createSearch(otherQuery),
      } as PartialLocation;
    },
    [createSearch, query],
  );
  const createRedirectToLocation = useCallback(
    (to = '/403') => {
      const { pathname } = location;

      return {
        pathname: to,
        search: createSearch({
          ...query,
          _from: pathname,
        }),
      } as PartialLocation;
    },
    [createSearch, location, query],
  );
  const createAuthorizedLocation = useCallback(() => {
    const { [queryKey]: _, ...otherQuery } = query;

    return {
      ...location,
      search: createSearch(otherQuery),
    } as PartialLocation;
  }, [createSearch, location, query, queryKey]);

  const { data: subscribeInfo, run: fetchSubscribeInfoRun } = useRequest(fetchSubscribeInfo, {
    manual: true,
  });
  useEffect(() => {
    authorize(token);
    if (token) {
      fetchSubscribeInfoRun();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorize, token, fetchSubscribeInfoRun]);

  return {
    loading,
    authorized,
    token,
    payload,
    tokenSource,
    authorize,
    unauthorize,
    createRestoreToLocation,
    createRedirectToLocation,
    createAuthorizedLocation,
    flagr,
    subscribeInfo,
  } as AuthorizationHookApi<Token>;
}

/**
 * 授权 api context
 */
export const AuthorizationApiContext = createNamedContext<AuthorizationHookApi | null>(
  'AuthorizationStateContext',
  null,
);

/**
 * 获取授权 api
 */
export function useAuthorization<T = unknown>() {
  const api = useContext(AuthorizationApiContext);

  if (api === null) {
    throw new Error(
      'useAuthorization() may be used only in the context of a <Authorization> component.',
    );
  }

  return api as AuthorizationHookApi<T>;
}

const { Provider: AuthorizationApiProvider } = AuthorizationApiContext;

/**
 * 授权容器属性
 */
export interface AuthorizationProps<T = unknown> extends AuthorizationHookOptions {
  /**
   * 授权中替换元素
   */
  fallback?: Refinable<() => React.ReactNode>;
  /**
   * 授权事件处理函数
   * @param token 授权 token
   * @param payload 授权信息
   */
  onAuthorize?(token: string, payload: T): void;
  /**
   * 取消授权事件处理函数
   * @param code 操作码
   * @param reason 原因
   */
  onUnauthorize?(code: UnauthorizeCode, reason: string): void;
  /**
   * 子元素
   */
  children?: Refinable<(api: AuthorizationHookApi) => React.ReactNode>;
}

/**
 * 授权容器
 * @param props 授权容器属性
 */
function Authorization<T = unknown>(props: AuthorizationProps<T>) {
  const {
    fallback = <AppLoading />,
    onAuthorize,
    onUnauthorize,
    storage,
    cacheKey,
    queryKey,
    children,
  } = props;

  const api = useAuthorizationApi({
    storage,
    cacheKey,
    queryKey,
  });
  const parentApiValue = useContext(AuthorizationApiContext);
  const apiValue = useMemo(() => {
    return parentApiValue === null ? api : parentApiValue;
  }, [api, parentApiValue]);

  useLayoutEffect(() => {
    const offAuthorize = authorizationEvents.on('authorize', (token, payload) => {
      if (onAuthorize) {
        onAuthorize(token, payload as T);
      }
    });

    const offUnauthorize = authorizationEvents.on('unauthorize', (code, reason) => {
      if (code === '@EVENT_TOKEN_REMOVE') {
        api.unauthorize('TOKEN_REMOVE', reason);
      } else if (code === '@EVENT_TOKEN_FORBIDDEN') {
        api.unauthorize('TOKEN_FORBIDDEN', reason);
      } else if (onUnauthorize) {
        onUnauthorize(code, reason);
      }
    });

    return () => {
      offAuthorize();
      offUnauthorize();
    };
  }, [api, onAuthorize, onUnauthorize]);
  if (api.loading) {
    return <>{refine(fallback)}</>;
  }

  return (
    <AuthorizationApiProvider value={apiValue}>
      {refine(children, apiValue)}
    </AuthorizationApiProvider>
  );
}
export function useStoreId() {
  return storeIdCache.get<string>();
}
export default Authorization;
