import { GrowthBookFactory } from '@leyan/growthbook-react';

export type MaxRound = {
  count: number;
};

export type KeyOfValue = {
  /** https://pre-growthbook-web.infra.leyantech.com/features/jd-marketing.follow-order.max-round */
  'jd-marketing.follow-order.max-round': number;
  /** 营销场景全开 https://pre-growthbook-web.infra.leyantech.com/features/doggy-more-marketing-scenarios */
  'doggy-more-marketing-scenarios': boolean;
};

export type Attributes = {
  storeId: string;
};

export const growthBookFactory = new GrowthBookFactory<KeyOfValue, Attributes>(
  {
    apiHost: 'https://pre-growthbook-proxy.leyanbot.com',
    clientKey: 'jd-marketing:ieXNw5oPLWjwbCwiQnbf',
    environment: 'production',
    appName: process.env.REACT_APP_NAME!,
    appVersion: process.env.REACT_APP_VERSION!,
  },
  {
    'jd-marketing.follow-order.max-round': {
      defaultValue: 3,
    },
    'doggy-more-marketing-scenarios': {
      defaultValue: false,
    },
  },
);
