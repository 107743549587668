/* eslint-disable camelcase */

import { resolve, Response, storehomeClient } from '../../../services/index';

export enum VERSION_CODE {
  /**
   * 言准-京东版
   */
  YAN_ZHUN_JD = '言准-京东版',
  /**
   * 言准-京东版-基础版
   */
  YAN_ZHUN_BASIC_JD = '言准-基础版',
}
export interface SubscribeInfo {
  domain: string;
  effective_time: number;
  product_version: VERSION_CODE;
  network_service_flag?: string;
  store_id: string;
  store_name: string;
  store_type: 'SELF_OPERATED_SHOP' | 'NON_SELF_OPERATED_SHOP';
  subscribe_status: number;
}

export function fetchSubscribeInfo() {
  return resolve(storehomeClient.get<Response<SubscribeInfo>>('/store/v1/subscribe_info'));
}
