import { DebugLogger, loggerManager } from '@leyan/logger';

const rootLogger = new DebugLogger(process.env.REACT_APP_NAME || '@');

loggerManager.setLogger(rootLogger);

rootLogger.enable();

const logger = loggerManager.getLogger();

export default logger;
